// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

const callVerifyRequest = () => {
  return {
    type: "CALL_VERIFY_REQUEST",
  };
}

const callVerifySuccess = (payload) => {
  return {
    type: "CALL_VERIFY_SUCCESS",
    payload: payload
  };
}

const callVerifyFailed = (payload) => {
  return {
    type: "CALL_VERIFY_FAILED",
    payload: payload,
  };
};



export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let name = await store
        .getState()
        .blockchain.smartContract.methods.name()
        .call();

      dispatch(
        fetchDataSuccess({
          name,
        })
      );
    } catch (err) {
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const verifyRequest = (secret, address) => {
  return async (dispatch) => {

    dispatch(callVerifyRequest())
    try {
      let result = await fetch(
        `https://40a7lyyiu8.execute-api.ap-east-1.amazonaws.com/prod/verify`,
        {
          method: "POST",

          headers: {
            Authorization: 'Bearer 41e1d55b4feb489ba051a8d0bd3f3818472cd7923008d5429b3a51c7a38fbf99',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'secret': secret,
            'address': address
          })
        })

      if (result.status != 200) {
        throw "INVALID"
      } else {
        let data = await result.text()
        dispatch(callVerifySuccess(`${address} is binded to ${data}`))
      }

    } catch (err) {
      console.log(err)
      dispatch(callVerifyFailed("FAIL"))
    }
  }
}