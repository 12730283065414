// constants
import Web3 from "web3";
import SmartContract from "../../contracts/SmartContract.json";
// log
import { fetchData } from "../data/dataActions";
import projectConfig from "../../project-config"
import { ethers } from "ethers";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    if (window.ethereum) {
      let web3 = new Web3(window.ethereum);
      try {
        let accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        accounts = accounts.map(a => ethers.utils.getAddress(a))
        const networkId = await window.ethereum.request({
          method: "net_version",
        });
        if (networkId == 4) {
          const SmartContractObj = new web3.eth.Contract(SmartContract, projectConfig.address)
          dispatch(connectSuccess({
            account: accounts[0],
            smartContract: SmartContractObj,
            web3: web3,
          }))
        }

        // Add listeners start
        window.ethereum.on("accountsChanged", (accounts) => {
          window.location.reload();
        });
        window.ethereum.on("chainChanged", () => {
          window.location.reload();
        });

      } catch (err) {
        dispatch(connectFailed("Something went wrong."));
      }
    } else {
      dispatch(connectFailed("Install Metamask."));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
