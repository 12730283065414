import React, { useEffect, useState } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import projectConfig from "./project-config"
import './fonts/fffforwa.ttf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'

export const StyledButton = styled.button`
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");

  const claimNfts = (amount) => {
    setClaimingNft(true);
    blockchain.smartContract.methods.mint(amount).send({
      from: blockchain.account,
      value: blockchain.web3.utils.toWei((projectConfig.cost * amount).toString(), "ether")
    }).once("error", (err) => {
      setClaimingNft(false);
      setFeedback("FAILED");
    }).then((receipt) => {
      setClaimingNft(false);
      setFeedback("OK");
    })
  }

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (
    <div>
      <s.Screen image="/assets/long_bg.png">
        <s.MessageContainer ai="flex-end">

          <s.Container flex={1} ai={"center"} jc={"center"}>
            <s.MessageContent>
              <p>ROADMAP</p>
              <ul>
                <li><span>1-Jan</span> WHITELIST</li>
                <li><span>7-Jan</span> PRESALE</li>
                <li><span>10-Jan</span> PUBLIC SALE</li>
              </ul>
              <p>Join DISCORD for MORE</p>
              <s.SocialMedia>
                <FontAwesomeIcon icon={faDiscord} size="3x" onClick={() => window.open(projectConfig.discord)} />
                <FontAwesomeIcon icon={faTwitter} size="3x" onClick={() => window.open(projectConfig.twitter)} />
              </s.SocialMedia>
            </s.MessageContent>
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <s.MintButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                }}
              >
                CONNECT
              </s.MintButton>) : (<s.MintButton
                disabled={claimingNft ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  claimNfts(1)
                }}
              >
                {claimingNft ? "MINT" : "MINT"}
              </s.MintButton>)}

          </s.Container>
        </s.MessageContainer>
      </s.Screen>
      )


    </div>
  );
}

export default App;
