import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData, verifyRequest } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import './fonts/fffforwa.ttf';

export const StyledButton = styled.button`
`;

function VerifyPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const errorMsg = useSelector((state) => state.data.errorMsg);
  const loading = useSelector((state) => state.data.loading);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (
    <div>
      <s.Screen image="/assets/avatar_bg.png">
        <s.MessageContainer ai="flex-end">
          {blockchain.account === "" || blockchain.smartContract === null ? (

            <s.Container flex={1} ai={"center"} jc={"center"}>
              <s.MessageContent>
                <p>WALLET VERIFY</p>
                <ul>
                  <li>Click 'Connect' to complete the process</li>
                </ul>


              </s.MessageContent>
              <s.MintButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                }}
              >
                CONNECT
              </s.MintButton>
              {blockchain.errorMsg !== "" ? (
                <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
              ) : null}
            </s.Container>
          ) : (
            <s.Container flex={1} ai={"center"} jc={"center"}>
              <s.MintButton
                disabled={loading ? 1 : 0}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(verifyRequest(id, blockchain.account))
                }}
              >
                VERIFY
              </s.MintButton>
              <s.Feedback error={errorMsg ? 1 : 0}>
                {loading ? "LOADING" : errorMsg}
              </s.Feedback>
            </s.Container>
          )}
        </s.MessageContainer>
      </s.Screen>

    </div>
  );
}

export default VerifyPage;
