import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;


`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display:flex;
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;

`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: 2vw;
  font-weight: 500;
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
`;

export const TextMint = styled.p`
color: var(--white);
font-family: "Impact";
&:before {  
  transform: scaleX(0);
  transform-origin: bottom right;
}
&:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}
&:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  inset: 0 0 0 0;
  background: hsl(200 100% 80%);
  z-index: -1;
  transition: transform .3s ease;
}
`

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const RoadMapContainer = styled.div`
display: flex;
flex-flow: column;
justify-content: flex-end;
position: absolute;
bottom: 0;
right: 0;
padding: 20px;
align-content: flex-end;

& div {
  font-family: "Impact";
  color: white;
}

& img {
  padding: 20px;
  position: absolute;
  right: 0;
  width: 100px;
  cursor: pointer;
}
`

export const MessageContainer = styled.div`
display:flex;
flex-direction: row;
color: #fff;
border-style: solid;
border-width: 4px;
border-color: black;
border-image-slice: 2;
border-image-width: 1;
border-image-outset: 0;
border-image-source: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='6' height='6'><path d='M0 2h2v2H0zM2 0h2v2H2zM4 2h2v2H4zM2 4h2v2H2z' fill='black' /></svg>");
position: relative;
background-color: white;

min-height: 50vh;
width: 35%;
transform: translate(60%, 50%);
margin: auto;
display: flex;
font-family: fffforwa;

@media only screen and (max-width: 1400px) {
  width: 50%;
  transform: translate(40%, 50%);
} 
@media only screen and (max-width: 600px) {
  width: 100%;
  transform: none;
  transform: translate(0px, 50%);
} 


`

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  & p {
    color: rgb(113,32,225);
    text-align: center;
    font-size: 150%;
    margin: 10px;
  }

  & ul {
    margin: 20px;
  }
  & ul li{
    margin: 10px;
  }

  & ul li span {
    color: rgb(113,32,225);
  }
  margin: 10px;
`

export const MintButton = styled.button`
font-family: fffforwa;
  font-weight: 800;
  background: transparent;
  min-width:100px;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 15px;
  display: inline-block;
  padding: 10px 16px;
  margin: 3px;
  cursor:pointer;
  transition-duration:0.1s;
  transition-timing-function: ease-in-out;
  color: rgb(113,32,225);
  background-color: #F5D300;
  border-color: rgb(113,32,225);

  &:hover {
    border-color: rgba(245, 211, 0, 0.3);
  }

  &:disabled {
      color: #666666;
      background-color: #999999;
      border-color: #666666;
  }

`

export const Feedback = styled.div`
  padding: 5px;
  text-align: center;
  color: ${({ error }) => error ? "red" : "rgb(113,32,225)"}

`

export const SocialMedia = styled.div`

  display: inline-flex;
  padding: 10px;
  width: 50%;
  justify-content: space-evenly;

  & svg {
    cursor: pointer;
  }
`